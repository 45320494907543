.container {
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    vertical-align: middle; 
    width: 500px;
    height: 350px;
}

.hidden-image {
    display: none;
    width: 300px;
    height: 300px;
}
.current-image {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    /* width: 300px;
    height: 300px; */
}

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
    cursor: pointer;
}
.arrow-left {
    left: 1rem;
}
.arrow-right {
    right: 1rem;
}

.circle-indicators {
    display: flex;
    height: 1rem;
    position: absolute;
    bottom: 1rem;
    
}
.circle-indicator {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    outline: none;
    margin: 0 0.1rem;
    cursor: pointer;
}
.current-indicator {
    background-color: white;
    border-color: yellow;
    background-color: yellow;
}



