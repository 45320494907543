
.star {
    outline-color: darkgrey;
}

.blank {
    color: darkgrey;
}

.full {
    color: yellow;
}